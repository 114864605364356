var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Select',{staticClass:"white",attrs:{"get-data-vuex":_vm.getFilteredLanguages,"placeholder":_vm.placeholder,"loading":_vm.isLoading,"required":_vm.required,"prop":"alias"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
var required = ref.required;
var computedValue = ref.computedValue;
var cssClass = ref.cssClass;
return [_c('input',_vm._g(_vm._b({class:cssClass,attrs:{"required":required && !computedValue}},'input',attributes,false),events))]}},{key:"text",fn:function(ref){
var option = ref.option;
return [_c('LanguageOutput',{attrs:{"language":option,"extended":""}})]}},{key:"selected-option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"media-content"},[_c('LanguageOutput',{attrs:{"language":option}})],1)]}}]),model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }