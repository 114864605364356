<template>
    <div>
        <Select
            v-model="selectedLanguage"
            class="white"
            :get-data-vuex="getFilteredLanguages"
            :placeholder="placeholder"
            :loading="isLoading"
            :required="required"
            prop="alias">
            <template #search="{ attributes, events, required, computedValue, cssClass }">
                <input
                    :class="cssClass"
                    :required="required && !computedValue"
                    v-bind="attributes"
                    v-on="events">
            </template>
            <template #text="{ option }">
                <LanguageOutput
                    :language="option"
                    extended></LanguageOutput>
            </template>
            <template #selected-option="{ option }">
                <div class="media-content">
                    <LanguageOutput :language="option"></LanguageOutput>
                </div>
            </template>
        </Select>
    </div>
</template>

<script>
  import {
    GET_LANGUAGES_INTERNATIONALIZED,
    GET_LANGUAGES_LIST
  } from "@core/store/action-constants";
  import Select from "@/components/Common/Select/Select.vue";
  import { mapActions, mapGetters } from "vuex";
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";
  import LanguageOutput from "@/components/Common/Output/LanguageOutput.vue";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";

  export default {
    name: "LanguagesSelect",
    extends: BaseSelect,
    components: {
      LanguageOutput,
      Select
    },
    computed: {
      ...mapGetters("languagesList", {
        languages: GET_LANGUAGES_INTERNATIONALIZED
      }),

      selectedLanguage: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      },

      isLoading () {
        return this.$wait(`languagesList/${ GET_LANGUAGES_LIST }`);
      }
    },
    methods: {
      ...mapActions("languagesList", [
        GET_LANGUAGES_LIST
      ]),

      async getFilteredLanguages (label) {
        if (this.languages.items.length === 0) {
          await this.GET_LANGUAGES_LIST();
        }
        if (label) {
          return filteredDataBySearch(this.languages.items, label);
        } else {
          return this.languages;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
